import {
  ChooseReturnMethod,
  IsomReturnOption,
  TaxKeys,
  mapMarketConfigToReturnMethods,
} from 'shared-frontend';
import { ModalBody } from '@ingka/modal';
import { useTranslation } from 'react-i18next';
import informationCircle from '@ingka/ssr-icon/paths/information-circle';
import InlineMessage from '@ingka/inline-message';
import { EntriesSkeleton } from '../EntriesSkeleton';
import { isValidatedMarketConfig } from '../../../store/features/api/marketConfig/marketConfig';
import { useAvailableReturnMethodsRequest, useQuestionnaireRequest } from '../../app/initialFetchUtils';
import {
  useGetSelectedReturnMethodDraft,
  useSelectedReturnMethodDraft,
  useGetSelectedReturnMethodState,
} from '../../../store/features/selectedReturnMethodSlice/selectedReturnMethodSlice';
import { useGoToSidebarPage } from '../../../store/features/sidebar/sidebarSlice';
import { useMarketConfigFetch } from '../../../useMarketConfigFetch';
import { useMarketLocale } from '../../../hooks/useMarketLocale';
import { useGetAppState } from '../../../store/features/appStateSlice/appStateSlice';

function NoAvailableOptions({ message }: { message: string }) {
  return (
    <InlineMessage
      body={message}
      className="mt-4"
      ssrIcon={informationCircle}
      subtle
      variant="informative"
    />
  );
}

function LoadingState() {
  return (
    <ModalBody>
      <EntriesSkeleton />
    </ModalBody>
  );
}

function ErrorState({ message }: { message: string }) {
  return (
    <ModalBody>
      <InlineMessage
        body={message}
        className="mt-4"
        ssrIcon={informationCircle}
        subtle
        variant="negative"
      />
    </ModalBody>
  );
}

export function ReturnMethodPage() {
  const { countryCode, languageCode } = useMarketLocale();
  const { data: marketConfig } = useMarketConfigFetch();
  const { data: returnMethods, isLoading, isError } = useAvailableReturnMethodsRequest();
  const { t } = useTranslation();
  const questionnaireRequest = useQuestionnaireRequest();

  const { setReturnMethodDraft } = useSelectedReturnMethodDraft();
  const goToCalendar = useGoToSidebarPage('time-window-calendar');
  const returnMethodMarketConfig = useGetSelectedReturnMethodState('returnMethodsMarketConfig');
  const selectedReturnMethod = useGetSelectedReturnMethodState('selectedReturnMethod');
  const selectedReturnMethodDraft = useGetSelectedReturnMethodDraft().selectedReturnMethod;
  const customerReturn = useGetAppState('customerReturn');
  const isCreditPayment = !!customerReturn?.isCreditPayment;

  if (!isValidatedMarketConfig(marketConfig)) {
    return null;
  }
  const { purchaseInformation } = marketConfig;
  const priceKey = purchaseInformation?.itemPriceExcludesTax
    ? TaxKeys.priceExclTax
    : TaxKeys.priceInclTax;

  if (isLoading || questionnaireRequest.isLoading) {
    return <LoadingState />;
  }
  if (isError || questionnaireRequest.isError) {
    return <ErrorState message={t('commonly-reused.errorMessage')} />;
  }
  if (!returnMethods) return null;

  const methodsWithConfig = returnMethodMarketConfig
    ? mapMarketConfigToReturnMethods({
      returnMethods,
      returnMethodMarketConfig,
      holdUntilPaidConfig: {
        skipRendering: isCreditPayment,
        countryCode,
        label: t('holdUntilPaid.returnOptionNotification'),
        languageCode,
      },
    })
    : returnMethods;

  const translations = {
    free: t('return-method-sidebar.free'),
    MISSING_SERVICE_PRICE: t('return-method-sidebar.MISSING_SERVICE_PRICE'),
    NO_TIMEWINDOWS: t('return-method-sidebar.NO_TIMEWINDOWS'),
    PLANNED_UNAVAILABILITY: t('error.service-planned-unavailable-text'),
    returnOptions: t('returnOptions', { returnObjects: true }),
    RPA_NOT_AVAILABLE: t('return-method-sidebar.RPA_NOT_AVAILABLE'),
  };

  return (
    <ModalBody>
      {returnMethods?.length === 0 ? (
        <NoAvailableOptions message={t('return-method-sidebar.no-available-options')} />
      ) : (
        <ChooseReturnMethod<IsomReturnOption>
          countryCode={countryCode}
          dateCaptionOnClick={goToCalendar}
          languageCode={languageCode}
          priceKey={priceKey}
          returnMethods={methodsWithConfig}
          selectedReturnMethod={selectedReturnMethodDraft || selectedReturnMethod}
          selectedReturnMethodOnChange={setReturnMethodDraft}
          translations={translations}
        />
      )}
    </ModalBody>
  );
}
