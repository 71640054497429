import { ModalBody } from '@ingka/modal';
import { IssueCategory } from '@resolutions/condition-of-goods';
import {
  consumerPublications,
  consumerSubscriptions,
  IssuePickerReq,
} from '@resolutions/issue-picker-contract';
import {
  publish, publishWithHandshake, subscribe, SubscriptionType, unsubscribe,
} from '@resolutions/pubsub-ts';
import { useEffect, useRef } from 'react';
import { useGetOTCState, useSetArticleIssue } from '../../../store/features/OTCSlice/OTCSlice';
import { getPurchaseType } from '../../../store/features/OTCSlice/apiMatchers';
import { isPoPItem, PopItem } from '../../../store/features/OTCSlice/reducerUtils';
import { useGetAppState } from '../../../store/features/appStateSlice/appStateSlice';
import { useMarketLocale } from '../../../hooks/useMarketLocale';

export function OTCIssuePickerBody() {
  const token = useGetAppState('accessToken');
  const articleInSidebar = useGetOTCState('articleInSidebar');
  const actionsSidebarPopId = useGetOTCState('actionsSidebarPopId');
  const statePops = useGetOTCState('pops');
  const stateBlindReturn = useGetOTCState('blindReturn');
  const { countryCode, languageCode } = useMarketLocale();
  const setArticleIssue = useSetArticleIssue();
  const hasSetupIssuePicker = useRef(false);

  useEffect(() => {
    if (hasSetupIssuePicker.current) return;
    hasSetupIssuePicker.current = true;

    const selectedItems = (() => {
      if (articleInSidebar) {
        return [articleInSidebar];
      }

      if (actionsSidebarPopId) {
        return statePops.find(
          (pop) => pop.id === actionsSidebarPopId,
        )?.items.filter((item) => item.checked);
      }

      return stateBlindReturn.items.filter((item) => isPoPItem(item) && item.checked) as PopItem[];
    })();

    if (!selectedItems) return;

    const issuePickerReq: IssuePickerReq = {
      configuration: {
        apiUrl: window.config.VITE_ISSUE_PICKER_API_URL,
        country: countryCode,
        headers: {
          'X-Authorization': `Bearer ${token}`,
          'X-Client-ID': window.config.VITE_ENTRA_CLIENT_ID,
        },
        language: languageCode,
      },
      isExtendedResponse: true,
      items: selectedItems.map((item) => {
        const type = (() => {
          if (!item.popId) return 'no_purchase_reference';

          return getPurchaseType(item.popId) === 'inStorePurchase' ? 'receipt' : 'sales_order';
        })();

        return {
          itemLineNo: item.itemLineId,
          itemNo: item.itemNo,
          itemType: item.itemType,
          quantity: item.quantity,
          purchaseReference: {
            receiptId: type === 'receipt' ? item.popId as string : undefined,
            salesOrderNo: type === 'sales_order' ? item.popId as string : undefined,
            type,
          },
          issueId: item.issue?.id,
        };
      }),
      pirId: '199bad05-c560-4eef-b057-e598d4e31636',
    };

    subscribe(
      {
        ...consumerSubscriptions.handshakeAcknowledge,
        type: SubscriptionType.Once,
      },
      () => {
        publish(consumerPublications.issuePickerRender);
        publishWithHandshake(consumerPublications.issuePickerReq, issuePickerReq);
      },
    );
    publish(consumerPublications.issuePickerHandShake);
  }, []);

  useEffect(() => {
    const selectedEvent = subscribe(consumerSubscriptions.issueSelected, (_, data) => {
      if (!data || !data.extended) return;

      const {
        extended: {
          actor, category, mainReasonCode, subReasonCode, title,
        },
        issueId: id,
      } = data;
      setArticleIssue({
        actor,
        category: category as IssueCategory,
        id,
        mainReasonCode,
        subReasonCode,
        title,
      });
    });

    return () => {
      unsubscribe(selectedEvent);
    };
  }, []);

  return (
    <ModalBody>
      <div id="issue-picker" />
    </ModalBody>
  );
}
