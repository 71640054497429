import { nanoid } from '@reduxjs/toolkit';
import { getEnabledFeatures } from 'shared-frontend';
import {
  CustomerReturn,
  Sac,
} from '../models';
import {
  CustomerReturnItem,
  PaidBy,
} from '../models/CustomerReturn';
import { ProgramCode, programCodeValues } from '../models/Sac';
import {
  ItemType,
  StateOfGoods,
  Unit,
  itemTypeValues,
  stateOfGoodsValues,
  unitValues,
} from '../models/SacItem';
import { MarketConfig } from '../store/features/api/marketConfig/marketConfig';
import { mapSacPriceToReturnPrice } from '../store/features/appStateSlice/utils';
import { isValueOfType } from './validators';

const validateEnumInSacNode = <T>(
  value: any, allowedValues: Readonly<T[]>, errorSacNode: string,
) => {
  const isValueValid = isValueOfType<T>(value, allowedValues);
  if (isValueValid) return '';

  return `${errorSacNode} has incorrect ENUM value "${value}": allowed values are "${allowedValues}"`;
};

const validateSacItemsLength = (sac: Sac) => (sac.items && sac.items.length > 0 ? '' : '\'items\' is required.');

const validateItemEnums = (sac: Sac) => {
  let validationMessage = '';

  sac.items.every((item, index) => {
    validationMessage = validateEnumInSacNode<ItemType>(item.itemType, itemTypeValues, `item[${index}].itemType`);
    if (validationMessage.length > 0) return false;

    if (item.itemType === 'ART') {
      validationMessage = validateEnumInSacNode<StateOfGoods>(item.stateOfGoods, stateOfGoodsValues, `item[${index}].stateOfGoods`);
      if (validationMessage.length > 0) return false;

      validationMessage = validateEnumInSacNode<Unit>(item.unit, unitValues, `item[${index}].unit`);
      if (validationMessage.length > 0) return false;
    }

    return true;
  });

  return validationMessage;
};

const validateSacItemsNumber = (sac: Sac) => {
  let validationMessage = '';

  sac.items.forEach((item, index) => {
    if (!item.itemNo || item.itemNo.length === 0) {
      validationMessage = `item[${index}] has a missing value for 'itemNo', which is required.`;
    }
  });

  return validationMessage;
};

const validateSacArticleItems = (sac: Sac) => {
  const firstValidationError = sac.items
    .filter((item) => item.itemType === 'ART')
    .map((item, index) => {
      const { orderReference } = item;
      if (!orderReference) return '';
      if (!orderReference.orderNumber) {
        return `item[${index}] has a missing value for 'orderNumber', which is required.`;
      }
      if (!orderReference.orderNumberSource) {
        return `item[${index}] has a missing value for 'orderNumberSource', which is required.`;
      }
      if (!orderReference.lineId) {
        return `item[${index}] has a missing value for 'lineId', which is required.`;
      }
      return '';
    })
    .find((message) => message);

  return firstValidationError || '';
};

const validateProgramCodeEnums = (sac: Sac) => {
  let validationMessage = '';

  if (sac.customer.loyaltyMemberships?.length) {
    sac.customer.loyaltyMemberships.every((membership, index) => {
      validationMessage = validateEnumInSacNode<ProgramCode>(
        membership.programCode,
        programCodeValues,
        `customer.loyaltyMemberships[${index}].programCode`,
      );
      return validationMessage.length === 0;
    });
  }

  return validationMessage;
};

const validateTaxPrice = (sac: Sac, marketConfig: MarketConfig | null) => {
  const { purchaseInformation } = marketConfig || {};
  const cfgShowExclTax = !!purchaseInformation?.itemPriceExcludesTax;

  const firstItem = sac.items[0];
  const { includingTax: sacShowInclTax } = firstItem.price;

  if ((sacShowInclTax === cfgShowExclTax) || (!sacShowInclTax === !cfgShowExclTax)) {
    return 'showing of prices with tax is not matching market config';
  }

  return '';
};

const validateAuthUserId = (sac: Sac) => (sac.auth.userId ? '' : 'auth.userId is required');

const validateSacId = (sac: Sac) => (sac.sacId ? '' : 'sacId is required');

const validateCausingBusinessUnit = (sac: Sac) => {
  const { causingBusinessUnit } = sac;
  if (!causingBusinessUnit) return 'causingBusinessUnit node is required';

  const { code, type } = causingBusinessUnit;
  return !code || !type
    ? 'causingBusinessUnit.code and causingBusinessUnit.type are required'
    : '';
};

const validateNumberOfLabels = (sac: Sac) => {
  const { numberOfLabels } = sac;
  if (!numberOfLabels || numberOfLabels < 1) {
    return 'numberOfLabels needs atleast to be 1';
  }

  return '';
};

export const validateSacForCustomerReturn = (
  sac: Sac,
  marketConfig: MarketConfig | null,
): { isValid: boolean; message: string } => {
  const setValid = (message: string = '') => ({ isValid: !message, message });

  const message = validateSacItemsLength(sac)
    || validateItemEnums(sac)
    || validateSacItemsNumber(sac)
    || validateSacArticleItems(sac)
    || validateProgramCodeEnums(sac)
    || validateTaxPrice(sac, marketConfig)
    || validateAuthUserId(sac)
    || validateSacId(sac)
    || validateCausingBusinessUnit(sac)
    || validateNumberOfLabels(sac);

  return setValid(message);
};

const resolvePaidBy = ({
  isPaidByTspEnabled,
  paidByIkea,
  paidByTsp,
}: {
  isPaidByTspEnabled: boolean,
  paidByIkea: boolean,
  paidByTsp?: boolean
}): PaidBy => {
  const paidByIsInvalid = paidByIkea && paidByTsp;
  switch (true) {
    case isPaidByTspEnabled && paidByIsInvalid:
      return 'INVALID';
    case isPaidByTspEnabled && paidByTsp:
      return 'TSP';
    case paidByIkea:
      return 'IKEA';
    default:
      return 'CUSTOMER';
  }
};

export function toCustomerReturn(
  sac: Sac,
  enabledFeatures: ReturnType<typeof getEnabledFeatures> = [],
): CustomerReturn {
  const {
    auth, isCredit, items, paidByIkea, paidByTsp, ...sacWithoutModifiedProps
  } = sac;
  const isPaidByTspEnabled = enabledFeatures.includes('showPaidByTsp');
  const paidBy = resolvePaidBy({ isPaidByTspEnabled, paidByIkea, paidByTsp });

  return {
    ...sacWithoutModifiedProps,
    paidBy,
    isCreditPayment: isCredit,
    items: [
      ...items.map((item) => {
        const mappedPrices = mapSacPriceToReturnPrice({
          originalPrice: item.originalPrice,
          price: item.price,
        });

        const baseReturnedItem = {
          ...item,
          ...mappedPrices,
          id: nanoid(),
          sourceLineRef: String(item.sacLineId),
          ...('stateOfGoods' in item && {
            conditionOfGoods: item.stateOfGoods,
          }),
          ...('reasonCode' in item && 'subReasonCode' in item && {
            returnReason: {
              mainReasonCode: item.reasonCode,
              subReasonCode: item.subReasonCode,
            },
          }),
        };

        return baseReturnedItem as CustomerReturnItem;
      }),
    ],
  };
}
