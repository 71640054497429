import Button from '@ingka/button';
import Collapsible from '@ingka/collapsible';
import chevronDown from '@ingka/ssr-icon/paths/chevron-down';
import {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

type CollapsibleState = {
  collapsibleSectionId: string;
  isExpanded: boolean;
  toggleExpand: () => void;
};
const CollapsibleContext = createContext<CollapsibleState | null>(null);

function useCollapsible() {
  const currentContext = useContext(CollapsibleContext);

  if (!currentContext) {
    throw new Error('useCollapsible has to be used within <CollapsibleContext.Provider>');
  }

  return currentContext;
}

export function CollapsibleProvider({ children, collapsibleSectionId }: {
  children: ReactNode, collapsibleSectionId: string,
}) {
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleExpand = () => setIsExpanded((prev) => !prev);

  const collapsibleState = useMemo(() => ({
    collapsibleSectionId,
    isExpanded,
    toggleExpand,
  }), [isExpanded]);

  return (
    <CollapsibleContext.Provider value={collapsibleState}>
      {children}
    </CollapsibleContext.Provider>
  );
}

export function CollapsibleSection({ children }: { children: ReactNode }) {
  const { collapsibleSectionId, isExpanded } = useCollapsible();

  return (
    <Collapsible
      className="[&_.collapsible\_\_content]:overflow-auto"
      data-testid="collapsible"
      expanded={isExpanded}
      id={collapsibleSectionId}
    >
      {children}
    </Collapsible>
  );
}

export function CollapsibleButton({ ariaPurchaseName, disabled }: {
  ariaPurchaseName: string, disabled: boolean
}) {
  const { collapsibleSectionId, isExpanded, toggleExpand } = useCollapsible();
  const { t } = useTranslation();
  const ariaLabel = isExpanded
    ? t('receipt-card.collapseBtnHide', { ariaPurchaseName })
    : t('receipt-card.collapseBtnShow', { ariaPurchaseName });

  return (
    <Button
      aria-controls={collapsibleSectionId}
      aria-expanded={isExpanded}
      aria-label={ariaLabel}
      className={
        `items-center ml-auto transition-all duration-200 ease-custom-ease transform ${isExpanded ? 'rotate-180' : 'rotate-0'}`
      }
      disabled={disabled}
      iconOnly
      onClick={toggleExpand}
      size="small"
      ssrIcon={chevronDown}
      type="secondary"
    />
  );
}
