import { getEnabledFeatures } from 'shared-frontend';
import { Config } from '../../config';
import { Sac } from '../../models';
import { store } from '../../store/app/store';
import { MarketConfig } from '../../store/features/api/marketConfig/marketConfig';
import { setInitialCreateMode } from '../../store/features/standAloneActions/extraReducersActions';
import { reportErrorToSentry, reportMessageToSentry } from '../reportErrorToSentry';
import { toCustomerReturn, validateSacForCustomerReturn } from '../sacUtils';
import { MarketConfigError, handleMarketConfigError, setupErrorMessage } from './handleSetupError';
import { STATUS, validateJWT } from '../tokenUtils';
import { base64ToUtf8String } from '../base64utils';
import { appStateSlice } from '../../store/features/appStateSlice/appStateSlice';
import { INIT_ERROR } from '../../components/InitErrorMessage/models';

const decodeAndParseSac = (encodedString: string) => {
  const sac: Sac = JSON.parse(base64ToUtf8String(encodedString));
  return sac;
};

export const getSanitizedSac = (sac: Sac): Omit<Sac, 'auth'> => {
  const { auth, ...sacWithoutAuth } = sac;
  return sacWithoutAuth;
};

type TrimSpaceSacArg = {
  accessor?: string,
  input: { [key: string]: any } | any[] | string | number,
  sacId: string,
};

export function trimSpacesSac({ accessor = 'rootOfSac', input, sacId }: TrimSpaceSacArg): any {
  if (typeof input === 'string') {
    if (input.startsWith(' ') || input.endsWith(' ')) {
      reportMessageToSentry(`the value for ${accessor} had to be trimmed when receiving SAC: ${sacId}`);
      return input.trim();
    }
    return input;
  } if (Array.isArray(input)) {
    return input.map((val) => trimSpacesSac({ input: val, accessor, sacId }));
  } if (typeof input === 'object' && input !== null) {
    return Object.entries(input)
      .reduce<{ [key: string]: any }>((acc, [key, value]) => (
      { ...acc, [key]: trimSpacesSac({ input: value, accessor: key, sacId }) }
    ), {});
  }
  return input;
}

export function sanitizeSac(sac: Sac): Sac {
  const id = sac.sacId;
  try {
    const sanitizedSac = trimSpacesSac({ input: sac, sacId: id });
    return sanitizedSac;
  } catch (e) {
    (e as Error).message = `Error while trimming spaces in SAC with id ${id}`;
    reportErrorToSentry(e);
    return sac;
  }
}

export const createInitReturnFunc = (cfg: Config, marketConfig: MarketConfig | any) => function initReturn(encodedSac: string = '') {
  try {
    const sac = sanitizeSac(decodeAndParseSac(encodedSac));

    handleMarketConfigError({ marketConfig, sacId: sac.sacId });

    window.getSanitizedSac = () => getSanitizedSac(sac);
    const validateJwtResult = validateJWT(sac.auth.accessToken, cfg);
    if (validateJwtResult === STATUS.INVALID) {
      store.dispatch(appStateSlice.actions.setInitError(validateJwtResult));
      return validateJwtResult;
    }

    const validateSacContent = validateSacForCustomerReturn(sac, marketConfig as MarketConfig);
    if (!validateSacContent.isValid) {
      const { customer, auth, ...sentrySacContent } = sac;
      const { message } = validateSacContent;
      const errorType = INIT_ERROR.SAC_VALIDATION_ERROR.error;

      const sentryErrorMessage = JSON.stringify({
        message,
        sac: sentrySacContent,
      });
      const sentryError = new Error(sentryErrorMessage);
      sentryError.name = errorType;
      reportErrorToSentry(sentryError);
      const sacValidationError = {
        error: errorType,
        message,
      };
      store.dispatch(appStateSlice.actions.setInitError(sacValidationError));
      return sacValidationError;
    }
    const enabledFeatures = marketConfig?.features
      ? getEnabledFeatures(marketConfig?.features)
      : [];
    store.dispatch(setInitialCreateMode({
      accessToken: sac.auth.accessToken,
      customerReturn: toCustomerReturn(sac, enabledFeatures),
      isAuthenticated: true,
      userId: sac.auth.userId,
    }));

    return STATUS.VALID;
  } catch (e) {
    const enrichedError = (e instanceof MarketConfigError) ? e : setupErrorMessage(e);
    reportErrorToSentry(enrichedError);
    return enrichedError;
  }
};
