import { useGetAppState } from './store/features/appStateSlice/appStateSlice';
import InitErrorMessage from './components/InitErrorMessage/InitErrorMessage';

interface App {
  children: JSX.Element | JSX.Element[] | null;
}

export function AuthenticatedApp({ children }: App) {
  const isAuthenticated = useGetAppState('isAuthenticated');
  const initError = useGetAppState('initError');
  const isAuthenticatedAndNoInitErrors = isAuthenticated && initError?.error === undefined;

  return isAuthenticatedAndNoInitErrors
    ? children
    : (<InitErrorMessage />);
}
