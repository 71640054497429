import { STATUS } from '../../utils/tokenUtils';

export const INIT_ERROR = {
  ...STATUS,
  MISSING_USER: {
    error: 'DEV_VIEW_MODE_MISSING_USER_ID',
    message: 'Missing userID in URL for view mode',
  },
  SAC_VALIDATION_ERROR: {
    error: 'INVALID_SAC_CONTENT',
    message: 'Failed to initialize SAC',
  },
} as const;

export type InitErrorActionPayload = {
  error: typeof INIT_ERROR[keyof typeof INIT_ERROR]['error'];
  message: string;
};
