import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import {
  BlindReturnItemFetching, filterBlindReturnItemsByItemNo, mapFetchedProductToStateItem,
  OTCState, PopItem, removeDots,
} from './reducerUtils';
import { apiSlice } from '../api/apiSlice';
import { IN_STORE_POP_NAME, ONLINE_POP_NAME } from '../../../hooks/useResolutionsScanner/validators';
import { Authorization, Product, ResolutionsTrail } from '../api/returnAuthorization/products';

export const getPurchaseType = (purchaseId: string) => {
  const inStoreRegex = /\d{18}|\d{22}/;
  return purchaseId.match(inStoreRegex) ? IN_STORE_POP_NAME : ONLINE_POP_NAME;
};

export const addApiMatchers = (builder: ActionReducerMapBuilder<OTCState>) => {
  builder
    .addMatcher(
      apiSlice.endpoints.getReturnAuthorizationProducts.matchPending,
      (state, action) => {
        const { purchaseId } = action.meta.arg.originalArgs;

        state.pops = [
          ...state.pops,
          {
            checked: false,
            fetchedResponse: null,
            id: action.meta.arg.originalArgs.purchaseId,
            isFetching: true,
            items: [],
            type: getPurchaseType(purchaseId),
          },
        ];
      },
    )
    .addMatcher(
      apiSlice.endpoints.getReturnAuthorizationProducts.matchRejected,
      (state, action) => {
        const { purchaseId } = action.meta.arg.originalArgs;
        const updatedPops = state.pops.filter((pop) => pop.id !== purchaseId);
        state.pops = updatedPops;
      },
    )
    .addMatcher(
      apiSlice.endpoints.getReturnAuthorizationProducts.matchFulfilled,
      (state, action) => {
        const { purchaseId } = action.meta.arg.originalArgs;
        const fetchedResponse = action.payload;

        const popIndexInState = state.pops.findIndex((pop) => pop.id === purchaseId);
        if (popIndexInState === -1) return;

        const getReturnedQuantity = (blindItemQuantity: number, productQuantity?: number) => {
          if (!productQuantity) return blindItemQuantity;
          return blindItemQuantity > productQuantity ? productQuantity : blindItemQuantity;
        };

        const createItem = ({
          authorizations,
          blindItem,
          maxQuantity,
          product,
          quantity,
          resolutionsTrail,
        }: {
          blindItem: PopItem | BlindReturnItemFetching,
          maxQuantity: number
          product: Product,
          quantity: number,
          authorizations: Authorization[],
          resolutionsTrail: ResolutionsTrail[],
        }): PopItem => ({
          ...mapFetchedProductToStateItem({
            itemLineId: product.lineId,
            product,
            quantity,
          }),
          authorizations,
          checked: state.blindReturn.checked,
          conditionOfGoods: ('conditionOfGoods' in blindItem) ? blindItem.conditionOfGoods : null,
          issue: ('issue' in blindItem) ? blindItem.issue : null,
          maxQuantity,
          popId: purchaseId,
          resolution: ('resolution' in blindItem) ? blindItem.resolution : 'RETURN_INSTORE',
          resolutionsTrail,
        });

        type MappedItems = {
          blindReturnItems: typeof state.blindReturn.items;
          popItems: PopItem[];
        };

        const mappedItems = state.blindReturn.items.reduce<MappedItems>((
          acc,
          blindItem,
        ) => {
          let remainingBlindItemQuantity = blindItem.quantity;
          for (let i = 0; i < fetchedResponse.products.length; i += 1) {
            const { authorizations, product, resolutionsTrail } = fetchedResponse.products[i];
            if (removeDots(product.productNumber) === blindItem.itemNo) {
              const quantity = getReturnedQuantity(remainingBlindItemQuantity, product.quantity);
              const maxQuantity = product.quantity ?? 1;
              acc.popItems.push(
                createItem({
                  authorizations,
                  blindItem,
                  maxQuantity,
                  product,
                  quantity,
                  resolutionsTrail,
                }),
              );

              remainingBlindItemQuantity -= quantity;
              if (remainingBlindItemQuantity <= 0) break;
            }
          }

          if (remainingBlindItemQuantity > 0) {
            acc.blindReturnItems.push({
              ...blindItem,
              quantity: remainingBlindItemQuantity,
            });
          }
          return acc;
        }, {
          blindReturnItems: [],
          popItems: [],
        });

        state.blindReturn.items = mappedItems.blindReturnItems;
        state.pops[popIndexInState] = {
          ...state.pops[popIndexInState],
          fetchedResponse,
          isFetching: false,
          items: mappedItems.popItems,
        };
      },
    )
    .addMatcher(apiSlice.endpoints.getProductInfo.matchPending, (state, action) => {
      const { productNumbers: itemNo } = action.meta.arg.originalArgs;
      const createItemLineId = () => {
        const blindReturnItems = state.blindReturn.items;
        if (blindReturnItems.length === 0) {
          return '0';
        }
        return `${parseInt(blindReturnItems[blindReturnItems.length - 1].itemLineId, 10) + 1}`;
      };
      state.blindReturn.items = [
        ...state.blindReturn.items,
        {
          isFetching: true,
          itemLineId: createItemLineId(),
          itemNo,
          quantity: 1,
        },
      ];
    })
    .addMatcher(apiSlice.endpoints.getProductInfo.matchRejected, (state, action) => {
      const { productNumbers } = action.meta.arg.originalArgs;
      filterBlindReturnItemsByItemNo(state, productNumbers);
    })
    .addMatcher(apiSlice.endpoints.getProductInfo.matchFulfilled, (state, action) => {
      const { productNumbers } = action.meta.arg.originalArgs;
      const [productInResponse] = action.payload;
      const updatedBlindReturnItems = state.blindReturn.items.map((item) => {
        if (item.itemNo !== productNumbers) return item;

        return {
          authorizations: [],
          checked: state.blindReturn.checked,
          resolution: 'RETURN_INSTORE' as const,
          conditionOfGoods: null,
          issue: null,
          popId: null,
          resolutionsTrail: [],
          maxQuantity: 999999999999999, // QuantityStepper breaks above this value
          ...mapFetchedProductToStateItem({
            itemLineId: item.itemLineId,
            product: productInResponse,
          }),
        };
      });
      state.blindReturn.items = updatedBlindReturnItems;
    });
};
