import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { SuccessfulScan } from '@resolutions/scanner-contract';
import { ItemAdded, OpenStatus } from '@resolutions/item-picker-contract';
import { SelectionState } from '@resolutions/condition-of-goods';
import { useLamnaSelector, useLamnaDispatch } from '../../app/hooks';
import {
  PopId,
} from '../../../hooks/useResolutionsScanner/validators/proofOfPurchase';
import {
  setActionsSidebarPop,
} from '../standAloneActions/actions';
import {
  filterBlindReturnItemsByItemNo,
  isPoPItem,
  IssueType,
  ItemRef,
  OTCState,
  PopItem,
  setArticleIssue,
  setArticleIssueComment,
  setConditionOfGoods,
  StatePop,
  updateItemsForPop,
  updateQuantityForPopItem,
} from './reducerUtils';
import { sidebarSlice } from '../sidebar/sidebarSlice';
import {
  removeArticle,
  removeBlindReturnArticle,
  removePoP,
  removeArticlesPop,
  showOTCArticleSidebar,
  SidebarPageKey,
} from '../standAloneActions/extraReducersActions';
import { addApiMatchers } from './apiMatchers';

export type PopSuccessfulScan = SuccessfulScan<PopId>;

export const OTCInitialState: OTCState = {
  actionsSidebarPopId: null,
  articleInSidebar: null,
  blindReturn: { checked: false, items: [] },
  pops: [],
  pipState: {
    isOpen: false,
    purchaseId: '',
  },
};

export const OTCSlice = createSlice({
  name: 'OTC',
  initialState: OTCInitialState,
  reducers: {
    updateItemsForPop,
    updateQuantityForPopItem,
    toggleBlindReturnAllChecked: (state) => {
      state.blindReturn = {
        checked: !state.blindReturn.checked,
        items: state.blindReturn.items.map((item) => ({
          ...item,
          checked: !state.blindReturn.checked,
        })),
      };
    },
    toggleBlindReturnItemChecked: (state, action: PayloadAction<{ itemLineId: string }>) => {
      state.blindReturn.items = state.blindReturn.items.map((item) => {
        if (item.itemLineId !== action.payload.itemLineId) return item;

        return {
          ...item,
          checked: !(item as PopItem).checked,
        };
      });
    },
    changeBlindReturnItemQuantity: (
      state,
      action: PayloadAction<{ itemNo: string, quantity?: number, }>,
    ) => {
      state.blindReturn.items = state.blindReturn.items.map((item) => {
        if (isPoPItem(item)) {
          if (item.itemNo !== action.payload.itemNo) return item;
          let quantity;
          const usingScan = action.payload.quantity === undefined;
          if (usingScan) {
            quantity = item.quantity + 1;
          } else {
            quantity = action.payload.quantity;
          }
          const restrictedQuantity = Number(quantity) > item.maxQuantity
            ? item.maxQuantity : quantity || 1;
          const popItemEntry = {
            ...item,
            quantity: restrictedQuantity,
          };
          state.articleInSidebar = popItemEntry;
          return popItemEntry;
        }
        return item;
      });
    },
    setArticleIssue,
    setArticleIssueComment,
    setPipState: (state, action: PayloadAction<OpenStatus>) => {
      const { openStatus, purchaseId } = action.payload;
      state.pipState = {
        isOpen: openStatus === 'open',
        purchaseId,
      };
    },
    togglePopChecked: (
      state: OTCState,
      action: PayloadAction<{ popId: string }>,
    ) => {
      const { popId } = action.payload;
      state.pops = state.pops.map((pop) => {
        if (pop.id !== popId) return pop;
        return {
          ...pop,
          items: pop.items.map((item) => ({
            ...item,
            checked: !pop.checked,
          })),
          checked: !pop.checked,
        };
      });
    },
    togglePopItemsChecked: (
      state: OTCState,
      action: PayloadAction<{ popId: string, itemLineId: string }>,
    ) => {
      const { popId, itemLineId } = action.payload;
      state.pops = state.pops.map((pop) => {
        if (pop.id !== popId) return pop;
        const { items } = pop;
        const foundItem = items.find((item) => item.itemLineId === itemLineId);
        if (!foundItem) return pop;
        return {
          ...pop,
          items: items.map((item) => {
            if (item.itemLineId !== itemLineId) return item;
            return {
              ...item,
              checked: !item.checked,
            };
          }),
        };
      });
    },
    setConditionOfGoods,
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        showOTCArticleSidebar,
        (
          state: OTCState,
          action: PayloadAction<{
            article: PopItem,
            sidebarPage: SidebarPageKey,
          }>,
        ) => {
          state.articleInSidebar = action.payload.article;
        },
      )
      .addCase(
        setActionsSidebarPop,
        (state: OTCState, action: PayloadAction<{ popId: string | null }>) => {
          state.actionsSidebarPopId = action.payload.popId;
        },
      )
      .addCase(
        sidebarSlice.actions.closeSidebar,
        (state: OTCState) => {
          state.actionsSidebarPopId = null;
          state.articleInSidebar = null;
        },
      )
      .addCase(removePoP, (state: OTCState) => {
        const popId = state.actionsSidebarPopId;
        state.pops = state.pops.filter((pop) => pop.id !== popId);
        state.actionsSidebarPopId = null;
      })
      .addCase(removeArticle, (state: OTCState) => {
        const articleToRemove = state.articleInSidebar;
        state.pops = state.pops.map((pop) => {
          if (pop.id !== articleToRemove?.popId) return pop;

          const updatedPop = { ...pop };
          updatedPop.items = pop.items.filter(
            (item) => item.itemLineId !== articleToRemove?.itemLineId,
          );
          return updatedPop;
        });
      })
      .addCase(removeBlindReturnArticle, (state) => {
        const articleToRemove = state.articleInSidebar;
        filterBlindReturnItemsByItemNo(state, articleToRemove?.itemNo);
      })
      .addCase(removeArticlesPop, (state: OTCState) => {
        const popId = state.actionsSidebarPopId;
        const filteredItems = (pop: StatePop) => {
          if (pop.id === popId) {
            const uncheckedItems = pop.items.filter((item) => item.checked === false);
            return {
              ...pop,
              items: uncheckedItems,
              checked: false,
            };
          }
          return pop;
        };
        state.pops = state.pops.map(filteredItems);
        state.actionsSidebarPopId = null;
      });
    addApiMatchers(builder);
  },
});

// convenience selectors
export const useGetOTCState = <Key extends keyof OTCState>(key: Key) => useLamnaSelector(
  (state) => state.otc[key],
);

export const useGetPop = (purchaseId: string) => useGetOTCState('pops').find((pop) => pop.id === purchaseId);

export const useGetAllItemsFromPops = () => {
  const pops = useGetOTCState('pops');
  return pops.flatMap((pop) => pop.items);
};

export const useGetItemsForPop = (popId: string | null = '') => {
  const matchingPop = useGetOTCState('pops').find((pop) => pop.id === popId);
  return matchingPop?.items.filter((item) => item.checked);
};

export const useGetItemInSidebar = () => useGetOTCState('articleInSidebar');

// convenience dispatchers
export const useTogglePopChecked = () => {
  const dispatch = useLamnaDispatch();
  return (popId: string) => {
    dispatch(OTCSlice.actions.togglePopChecked({ popId }));
  };
};

export const useTogglePopItemsChecked = () => {
  const dispatch = useLamnaDispatch();
  return (popId: string, itemLineId: string) => {
    dispatch(OTCSlice.actions.togglePopItemsChecked({ popId, itemLineId }));
  };
};
export const useUpdateItemsForPop = () => {
  const dispatch = useLamnaDispatch();
  return (itemAdded: ItemAdded) => {
    dispatch(OTCSlice.actions.updateItemsForPop(itemAdded));
  };
};

export const useToggleBlindReturnAllChecked = () => {
  const dispatch = useLamnaDispatch();
  return () => {
    dispatch(OTCSlice.actions.toggleBlindReturnAllChecked());
  };
};

export const useToggleBlindReturnItemChecked = () => {
  const dispatch = useLamnaDispatch();
  return (itemLineId: string) => {
    dispatch(OTCSlice.actions.toggleBlindReturnItemChecked({ itemLineId }));
  };
};

export const useChangeBlindReturnItemQuantity = () => {
  const dispatch = useLamnaDispatch();
  return (itemNo: string, quantity?: number) => {
    dispatch(OTCSlice.actions.changeBlindReturnItemQuantity({ itemNo, quantity }));
  };
};

export const useChangeItemsQuantityForArticle = (popItem: PopItem) => {
  const dispatch = useLamnaDispatch();
  const changeBlindQuantity = useChangeBlindReturnItemQuantity();
  const isBlindReturn = !popItem.popId;
  if (isBlindReturn) {
    return {
      increase: () => changeBlindQuantity(popItem.itemNo, popItem.quantity + 1),
      decrease: () => changeBlindQuantity(popItem.itemNo, popItem.quantity - 1),
      inputChange: (quantity: number) => changeBlindQuantity(popItem.itemNo, quantity),
    };
  }

  return {
    increase: () => dispatch(OTCSlice.actions.updateItemsForPop({
      purchaseId: popItem.popId!, item: { ...popItem, quantity: 1 },
    })),
    decrease: () => dispatch(OTCSlice.actions.updateItemsForPop({
      purchaseId: popItem.popId!, item: { ...popItem, quantity: -1 },
    })),
    inputChange: (quantity: number) => dispatch(OTCSlice.actions.updateQuantityForPopItem(
      { purchaseId: popItem.popId!, item: { ...popItem, quantity } },
    )),
  };
};

export const useSetOpenPip = () => {
  const dispatch = useLamnaDispatch();
  return (pipState: OpenStatus) => {
    dispatch(OTCSlice.actions.setPipState(pipState));
  };
};

export const useSetConditionOfGoods = () => {
  const dispatch = useLamnaDispatch();
  return (selection: SelectionState) => {
    dispatch(OTCSlice.actions.setConditionOfGoods(selection));
  };
};

export const useSetArticleIssue = () => {
  const dispatch = useLamnaDispatch();
  return (issue: IssueType) => {
    dispatch(OTCSlice.actions.setArticleIssue({ issue }));
  };
};

export const useSetArticleIssueComment = () => {
  const dispatch = useLamnaDispatch();
  return (comment: string, itemRef: ItemRef) => {
    dispatch(OTCSlice.actions.setArticleIssueComment({ comment, itemRef }));
  };
};
