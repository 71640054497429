import Text from '@ingka/text';
import { useTranslation } from 'react-i18next';
import { ErrorFallback } from 'shared-frontend';
import { useOTCRoute } from '../../hooks/useAppRoutes';
import { useGetAppState } from '../../store/features/appStateSlice/appStateSlice';
import { INIT_ERROR } from './models';

function CustomErrorMessage() {
  const accessToken = useGetAppState('accessToken');
  const initError = useGetAppState('initError');
  const isAuthenticated = useGetAppState('isAuthenticated');
  const hasFailed = !isAuthenticated && accessToken === 'failed';
  const { t } = useTranslation();

  if (!hasFailed) return null;

  const errorMessage = initError?.message ?? t('error.fallback-message');
  const isInvalidSac = initError?.error === INIT_ERROR.SAC_VALIDATION_ERROR.error;
  const errorTitle = isInvalidSac ? t('error.invalid-sac-title') : t('error.fallback-title');

  return (
    <div className="text-center">
      <Text tagName="h1" headingSize="l" className="mb-8">{errorTitle}</Text>
      {isInvalidSac && <Text bodySize="l">{t('error.invalid-sac-message')}</Text>}
      <Text bodySize="l" className="pt-2">{errorMessage}</Text>
    </div>
  );
}

export default function InitErrorMessage() {
  const { t } = useTranslation();
  const isOTCRoute = useOTCRoute();

  return (
    isOTCRoute ? (
      <ErrorFallback
        title={t('error.fallback-title')}
        body={t('error.authentication-failed')}
        testId="authentication-failed"
      />
    ) : <CustomErrorMessage />

  );
}
