import { ModalFooter } from '@ingka/modal';
import { useTranslation } from 'react-i18next';
import Button from '@ingka/button';
import { useCloseSidebar, useGoToSidebarPage } from '../../../store/features/sidebar/sidebarSlice';
import { useGetOTCState } from '../../../store/features/OTCSlice/OTCSlice';
import { useShowOTCArticleSidebar } from '../../../store/features/standAloneActions/actions';
import { isPoPItem, StatePop } from '../../../store/features/OTCSlice/reducerUtils';

function Wrapper({ children }: { children: React.ReactNode }) {
  return <ModalFooter className="modal-footer--dual-action" renderBorder>{children}</ModalFooter>;
}

export function OTCIssuePickerFooter() {
  const { t } = useTranslation();
  const close = useCloseSidebar();
  const articleInSidebar = useGetOTCState('articleInSidebar');
  const actionsSidebarPopId = useGetOTCState('actionsSidebarPopId');
  const statePops = useGetOTCState('pops');
  const stateBlindReturn = useGetOTCState('blindReturn');
  const showOTCArticleSidebar = useShowOTCArticleSidebar();
  const showBulkConditionOfGoodsSidebar = useGoToSidebarPage('otc-condition-of-goods');

  const checkedBulkItems = (() => {
    if (articleInSidebar) return null;

    let popInState: StatePop | undefined;
    if (actionsSidebarPopId) {
      popInState = statePops.find((pop) => pop.id === actionsSidebarPopId);
      if (!popInState) return null;
    }

    const items = popInState
      ? popInState.items
      : stateBlindReturn.items.filter(isPoPItem);

    return items.filter((item) => item.checked);
  })();

  const onPrimaryButtonClick = () => {
    if (articleInSidebar) {
      const { itemType } = articleInSidebar;
      if (itemType === 'SGR') {
        close();
        return;
      }

      showOTCArticleSidebar(articleInSidebar, 'otc-condition-of-goods');
      return;
    }

    const allItemsAreSGRs = checkedBulkItems?.every((item) => item.itemType === 'SGR');
    if (allItemsAreSGRs) {
      close();
    } else {
      showBulkConditionOfGoodsSidebar();
    }
  };

  const isPrimaryButtonDisabled = () => {
    if (articleInSidebar) return !articleInSidebar.issue;

    return checkedBulkItems ? checkedBulkItems.some((item) => !item.issue) : true;
  };

  return (
    <Wrapper>
      <Button text={t('commonly-reused.close')} onClick={close} />
      <Button
        type="primary"
        disabled={isPrimaryButtonDisabled()}
        text={t('commonly-reused.continue')}
        onClick={onPrimaryButtonClick}
      />
    </Wrapper>
  );
}
