import QuantityStepper from '@ingka/quantity-stepper';
import { useTranslation } from 'react-i18next';
import { PopItem } from '../../store/features/OTCSlice/reducerUtils';
import { useChangeItemsQuantityForArticle } from '../../store/features/OTCSlice/OTCSlice';

export function OTCArticleQuantity({ item }: { item: PopItem }) {
  const { t } = useTranslation();
  const { increase, decrease, inputChange } = useChangeItemsQuantityForArticle(item);

  return (
    <QuantityStepper
      ariaDescribedBy={
        t('otc-article-table.quantity-stepper.ariaDescription', { ariaProductName: item.name })
      }
      ariaDescribedById={`quantity-stepper-helper-${item.itemLineId}`}
      ariaLabelIncrease={t('otc-article-table.quantity-stepper.ariaIncrease')}
      ariaLabelDecrease={t('otc-article-table.quantity-stepper.ariaDecrease')}
      ariaLabelInput={t('otc-article-table.quantity-stepper.ariaInput')}
      className="
        [&.quantity-stepper__input:disabled]:text-lightest
        [&.quantity-stepper__input]:p-0
        [&_.quantity-stepper\_\_increase]:my-[2px]
      "
      data-testid="quantity-stepper"
      fluid={false}
      maxVal={item.maxQuantity}
      minVal={1}
      onDecrease={decrease}
      onIncrease={increase}
      onInputChange={(e) => { inputChange(Number(e.currentTarget.value)); }}
      small
      value={item.quantity}
    />
  );
}
