import { TaxKeys } from 'shared-frontend';
import { useGetAppState } from '../../store/features/appStateSlice/appStateSlice';
import { useGetSelectedReturnMethodState } from '../../store/features/selectedReturnMethodSlice/selectedReturnMethodSlice';
import { useMarketConfigFetch } from '../../useMarketConfigFetch';

export const useShouldHoldUntilPaid = () => {
  const selectedReturnMethod = useGetSelectedReturnMethodState('selectedReturnMethod');
  const customerReturn = useGetAppState('customerReturn');
  const { data: marketConfig } = useMarketConfigFetch();
  if (!selectedReturnMethod) return false;
  const purchaseInformation = marketConfig?.purchaseInformation;
  const priceKey = purchaseInformation?.itemPriceExcludesTax
    ? TaxKeys.priceExclTax
    : TaxKeys.priceInclTax;
  const price = selectedReturnMethod?.price[priceKey] as number;

  return selectedReturnMethod?.marketConfig?.holdUntilPaid?.enabled
  && typeof price === 'number'
  && price > 0
  && customerReturn?.paidBy === 'CUSTOMER'
  && !selectedReturnMethod?.customerPaymentReceived
  && !customerReturn.isCreditPayment;
};
