import Button from '@ingka/button';
import { useTranslation } from 'react-i18next';
import Text from '@ingka/text';
import { SelectionState, PackageCondition } from '@resolutions/condition-of-goods';
import { PopItem } from '../../store/features/OTCSlice/reducerUtils';
import { UnderlineTextButton } from '../UnderlineTextButton/UnderlineTextButton';
import { useShowOTCArticleSidebar } from '../../store/features/standAloneActions/actions';

function getConditionOfGoodsKey({ isAssembled, packageCondition }: SelectionState) {
  if (isAssembled) {
    return 'otc-article-table.issue-column.assembled';
  }

  if (packageCondition === PackageCondition.OPEN_DAMAGED) {
    return 'otc-article-table.issue-column.openDamaged';
  }

  return 'otc-article-table.issue-column.originalPack';
}

export function ArticleIssueCell({ item }: { item: PopItem }) {
  const { t } = useTranslation();
  const showOTCArticleSidebar = useShowOTCArticleSidebar();
  const openArticleActionsSidebar = () => showOTCArticleSidebar(item, 'otc-issue-picker');

  if (!item.issue) {
    return (
      <Button
        onClick={openArticleActionsSidebar}
        type="primary"
        size="small"
        text={t('otc-article-table.issue-column.selectIssueBtn')}
      />
    );
  }
  const {
    actor,
    mainReasonCode,
    subReasonCode,
    title,
  } = item.issue;
  const samsString = `SAMS: ${mainReasonCode}${subReasonCode}`;
  const issueString = `${actor}, ${title}`;
  const showConditionOfGoodsButton = item.itemType !== 'SGR';

  const showConditionOfGoodsModal = () => showOTCArticleSidebar(item, 'otc-condition-of-goods');

  return (
    <div className="flex flex-col items-start">
      <UnderlineTextButton
        onClick={openArticleActionsSidebar}
        text={(
          <>
            <Text tagName="span" bodySize="m" className="block text-dark">{issueString}</Text>
            <Text tagName="span" bodySize="m" className="block text-textAndIcon-4">{samsString}</Text>
          </>
        )}
      />
      {showConditionOfGoodsButton && (
        item.conditionOfGoods ? (
          <UnderlineTextButton
            onClick={showConditionOfGoodsModal}
            text={(
              <Text tagName="span" bodySize="m" className="text-dark">
                {t(getConditionOfGoodsKey(item.conditionOfGoods))}
              </Text>
            )}
          />
        ) : (
          <Button
            onClick={showConditionOfGoodsModal}
            type="primary"
            size="xsmall"
            text={t('otc-article-table.issue-column.selectConditionOfGoodsBtn')}
            className="[&_span]:!px-2 [&_span]:!py-1 [&_span]:!min-h-0 mt-2"
          />
        )
      )}
    </div>
  );
}
