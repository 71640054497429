import { useEffect, useRef, useState } from 'react';

export type SaveOnUnmountProps<T> = {
  value: T;
  onUnmount: (value: T) => void;
};

export function useSaveOnUnmount<T=string>({
  value,
  onUnmount,
}: SaveOnUnmountProps<T>): [T, (value: T) => void] {
  const [state, setState] = useState<SaveOnUnmountProps<T>['value']>(value);
  const initialValueRef = useRef<SaveOnUnmountProps<T>['value']>(value);
  const liveValueRef = useRef<SaveOnUnmountProps<T>['value']>(value);

  useEffect(() => {
    liveValueRef.current = state;
  }, [state]);

  useEffect(() => () => {
    if (liveValueRef?.current === initialValueRef.current) { return; }
    onUnmount(liveValueRef.current);
  }, []);
  return [state, setState];
}
